<template>
  <cs-page>
    <div
      class="cs-page-setting bg-white position-absolute top-50 start-50 translate-middle"
    >
      <p class="title text-center">{{ config.title }}</p>
      <a-steps :current="current">
        <template slot="progressDot" slot-scope="{ index, status, prefixCls }">
          <div class="step-content" :class="{ active: index <= current }">
            <template v-if="index === 0">
              <cs-icon
                name="icon-yanzhengshouji"
                :type="index <= current ? 'symbol' : 'font'"
                size="35"
              />
              <p>验证手机</p>
            </template>
            <template v-else-if="index === 1">
              <cs-icon
                :name="config.icon"
                :type="index <= current ? 'symbol' : 'font'"
                size="35"
              />
              <p>
                {{ config.content }}
              </p>
            </template>
            <template v-else-if="index === 2">
              <cs-icon
                name="icon-xiugaiwancheng"
                :type="index <= current ? 'symbol' : 'font'"
                size="35"
              />
              <p>完成</p>
            </template>
          </div>
          <span :class="`${prefixCls}-icon-dot`" />
        </template>
        <a-step />
        <a-step />
        <a-step />
      </a-steps>

      <div v-if="current === 0">
        <a-form-model
          ref="form"
          :model="formModel"
          :rules="formRules"
          class="mx-auto"
          @submit.native.prevent
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-model-item label="手机号码">
            <a-input
              v-model.trim="userInfo.userName"
              placeholder="请输入手机号"
              :maxLength="11"
              :disabled="true"
            >
            </a-input>
          </a-form-model-item>

          <a-form-model-item prop="code" label="短信验证码">
            <a-input
              v-model="formModel.code"
              :maxLength="6"
              placeholder="请输入短信验证码"
            >
              <span slot="addonAfter" class="btnSendCode">
                <cs-countdown
                  text="发送验证码"
                  ref="CsCountdown"
                  :validate="validateCode"
                  v-slot="{ time }"
                  >{{ time }}s后重试</cs-countdown
                >
              </span>
            </a-input>
          </a-form-model-item>
          <p class="tips">
            如果1分钟内没有收到验证短信，请重新获取，验证码半小时内有效
          </p>
          <a-button
            type="primary"
            block
            style="margin-top: 30px"
            v-click.timeout="2"
            @click="checkCodeClick"
            >下一步</a-button
          >
        </a-form-model>
      </div>
      <div v-else-if="current === 1">
        <slot></slot>
      </div>
      <div v-else-if="current === 2">
        <a-row style="position: absolute; width: 500px">
          <a-col :span="7" class="text-end">
            <cs-icon
              name="icon-yiwancheng-"
              type="symbol"
              size="50"
              style="margin-top: 2px; margin-right: 15px"
            />
          </a-col>
          <a-col :span="17">
            <p class="success-tips">{{ config.success }}</p>
            <p class="success-skip">
              {{ step }}秒后自动跳转登录页，
              <router-link to="/login" class="text-primary"
                >立即跳转</router-link
              >
            </p>
          </a-col>
        </a-row>
      </div>
    </div>
  </cs-page>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    name: String,
  },
  data() {
    return {
      formModel: {
        mobile: "",
        code: "",
      },
      formRules: {},
      authCode: "",
      current: 0,
      step: 5,
      // 退出定时器
      logoutTimer: null,
    };
  },
  inject: ["loadUserInfo"],
  computed: {
    ...mapState({ userInfo: (state) => state.store.userInfo }),
    config() {
      const data = {
        password: {
          title: "重设密码",
          content: "重新设置密码",
          icon: "icon-xiugaimima",
          success: "重设密码成功",
          bizType: 11,
        },
        logout: {
          title: "注销账号",
          content: "注销手机",
          icon: "icon-zhuxiaoshouji",
          success: "注销成功",
          bizType: 12,
        },
        tel: {
          title: "修改手机号",
          content: "修改手机号",
          icon: "icon-xiugaishoujihao",
          success: "修改手机号成功",
          bizType: 13,
        },
      };
      return data[this.name];
    },
  },
  created() {
    this.loadUserInfo();
  },
  beforeDestroy() {
    if (this.logoutTimer) {
      clearInterval(this.logoutTimer);
    }
  },
  methods: {
    // 获取验证码
    async validateCode() {
      const { bizType } = this.config;
      const {
        code,
        msg,
      } = await this.api.service.uac_oapi_msg_portalSendMsgByLogin(
        { bizType, mobile: this.userInfo.userName, source: 4 },
        {}
      );
      await this.api.toast({ code, msg });
    },
    checkCodeClick() {
      const { bizType } = this.config;
      if (this.formModel.code) {
        this.api.service
          .uac_oapi_msg_checkVcByLogin(
            {
              bizType,
              code: this.formModel.code,
              mobile: this.userInfo.userName,
            },
            {}
          )
          .then(async ({ code, data, msg }) => {
            this.$refs.CsCountdown.reset();
            await this.api.toast({ code, msg }, 0);
            this.authCode = data;
            this.next();
          });
      } else {
        this.$message.error("请输入短信验证码");
      }
    },
    next() {
      this.current += 1;
      if (this.current === 2) {
        // 5秒后跳转页面
        this.logoutTimer = setInterval(() => {
          this.step -= 1;
          if (this.step <= 0) {
            clearInterval(this.logoutTimer);
            this.api.logout();
          }
        }, 1000);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.cs-page-setting {
  width: 1000px;
  height: 578px;
  padding: 0 150px;
  box-shadow: 0 10px 14px 0 rgba(77, 83, 98, 0.06);
  border-radius: 4px;
  .title {
    margin-top: 48px;
    font-size: 18px;
    color: #333333;
  }
  .btnSendCode {
    color: black;
    cursor: pointer;
  }
  .ant-steps {
    margin-top: 120px;
    margin-bottom: 50px;
    .step-content {
      position: absolute;
      margin-top: -80px;
      margin-left: -45px;
      width: 100px;
      font-size: 14px;
      color: #bbbbbb;
      p {
        padding: 13px 0;
      }
      &.active {
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .ant-form {
    width: 360px;
    .ant-form-item {
      margin-bottom: 15px;
    }
    .tips {
      font-size: 12px;
      color: #999999;
    }
    .success-tips {
      font-size: 18px;
      color: #333333;
      margin-bottom: 0;
    }
    .success-skip {
      font-size: 14px;
      color: #999999;
    }
  }
}
</style>
